// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  propertyIdentifier = 120,
  identifier = 121,
  nameIdentifier = 122,
  insertSemi = 123,
  LineComment = 1,
  BlockComment = 2,
  Expression = 3,
  expression0 = 127,
  ForExpression = 4,
  forExpressionStart = 130,
  ForInExpressions = 6,
  ForInExpression = 7,
  Name = 8,
  Identifier = 9,
  AdditionalIdentifier = 10,
  minus = 11,
  plus = 12,
  divide = 13,
  multi = 14,
  exp = 15,
  inExpressionSep = 135,
  IterationContext = 17,
  forExpressionBodyStart = 138,
  IfExpression = 19,
  ifExpressionStart = 139,
  QuantifiedExpression = 23,
  quantifiedExpressionStart = 140,
  QuantifiedInExpressions = 26,
  QuantifiedInExpression = 27,
  Disjunction = 29,
  Conjunction = 31,
  Comparison = 33,
  PositiveUnaryTest = 37,
  PositiveUnaryTests = 39,
  ArithmeticExpression = 41,
  arithmeticPlusStart = 144,
  arithmeticTimesStart = 145,
  arithmeticExpStart = 146,
  arithmeticUnaryStart = 147,
  InstanceOfExpression = 42,
  Type = 45,
  QualifiedName = 46,
  VariableName = 47,
  SpecialType = 48,
  ContextEntryTypes = 61,
  ContextEntryType = 62,
  ArgumentTypes = 65,
  ArgumentType = 66,
  PathExpression = 67,
  pathExpressionStart = 152,
  FilterExpression = 69,
  filterExpressionStart = 153,
  FunctionInvocation = 71,
  functionInvocationStart = 154,
  SpecialFunctionName = 72,
  functionInvocationParameters = 155,
  NamedParameters = 73,
  NamedParameter = 74,
  ParameterName = 75,
  PositionalParameters = 76,
  literal = 158,
  nil = 159,
  NumericLiteral = 78,
  StringLiteral = 79,
  BooleanLiteral = 80,
  DateTimeLiteral = 81,
  DateTimeConstructor = 82,
  AtLiteral = 83,
  SimplePositiveUnaryTest = 85,
  Interval = 86,
  intervalStart = 165,
  intervalEnd = 166,
  ParenthesizedExpression = 87,
  parenthesizedStart = 167,
  parenthesizedEnd = 168,
  List = 88,
  listStart = 170,
  listEnd = 171,
  FunctionDefinition = 89,
  functionDefinitionStart = 172,
  FormalParameters = 90,
  FormalParameter = 91,
  Context = 96,
  contextStart = 174,
  ContextEntry = 97,
  Key = 98,
  PropertyName = 99,
  PropertyIdentifier = 100,
  Expressions = 101,
  UnaryTests = 102
